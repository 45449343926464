import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

import {AuthService} from '../services';


@Injectable({ providedIn: 'root' })
export class RoleGuard implements CanActivate {

  constructor(
    private _authService: AuthService,
    private _router: Router
  ) { }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const currentUser = this._authService.currentUserValue;
    // console.log('role guard current user ->', currentUser);
    if (currentUser) {
      // check if route is restricted by role
      if (route.data.expectedRoles && route.data.expectedRoles.indexOf(currentUser.role) === -1) {
        // role not authorised so redirect to home page
        // TODO fix me
        this._router.navigate(['/m', 'dashboard']);
        return false;
      }

      // authorised so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    // TODO fix me
    this._router.navigate(['/m', 'dashboard']);
    return false;
  }
  /*
  private _checkRole(route: ActivatedRouteSnapshot): Observable<boolean> {
      const expectedRoles = route.data.expectedRoles;

      return this.authService.currentUser.pipe(take(1),
          map(userData => {
              if (!userData) {
                  console.log('rg no user data');
                  return false;
              }
              if (!expectedRoles) {
                  console.log('rg no expected roles');
                  return true;
              }
              if (expectedRoles.includes(userData.currentRole)) {
                  console.log('rg check ok');
                  return true;
              } else {
                  console.log('Unauthorized role:' + userData.currentRole);
                  // this.router.navigate(['dashboard']);
                  this.router.navigateByUrl(APP_ROUTE_PATHS.members_module.dashboard.base_route);
                  return false;
              }
              return false;
          }));
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

      // this will be passed from the route config
      // on the data property

      return this._checkRole(route);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
      return this.canActivate(route, state);
  }


  canLoad(route: Route): Observable<boolean> {

      const expectedRoles = route.data.expectedRoles;
      if (!route.data.expectedRoles) {
          return of(true);
      }

      return this.authService.currentUser.pipe(take(1),
          map(userData => {
              if (!userData) {
                  return false;
              }

              if (route.data.expectedRoles.includes(userData.currentRole)) {
                  return true;
              } else {
                  console.log('Unauthorized role:' + userData.currentRole);
                  // this.router.navigate(['dashboard']);
                  this.router.navigateByUrl(APP_ROUTE_PATHS.members_module.dashboard.base_route);
              }
              return false;
          }));
  }



   */

}


