import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot} from '@angular/router';

import {map, take} from 'rxjs/operators';
import {Observable} from 'rxjs';


import {AuthService} from '../services';


@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {}


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const url: string = state.url;
    // let url = `/${route.url}`;
    return this.checkLogin(url);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(route, state);
  }

  canLoad(route: Route): Observable<boolean> {
    const url = `/${route.path}`;
    return this.checkLogin(url);
  }

  checkLogin(url: string): Observable<boolean> {

    // return this.authService.isAuthenticated;

    return this.authService.isAuthenticated.pipe(take(1),
      map(isAuth => {
        if (!isAuth)  {
          console.log('Not signed in, next url ->', url);
          // Store the attempted URL for redirecting
          this.authService.redirectUrl = url;
          this.router.navigate(['/authentication', 'signin']);
        }

        return isAuth;
      }));

  }
}
