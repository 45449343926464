import {DetailedAuthentication} from './detailed-authentication.model';
import {BaseProfilePictureInt} from './profile-picture.model';
import {UserBaseInt} from './user-basic-model';

export interface CurrentUserInt extends UserBaseInt {
  allowNotifications: boolean;
  Authentication: DetailedAuthentication;
}

export class CurrentUser {
  id: number;
  name: string;
  surname: string;
  role: string;
  allowNotifications: boolean;
  Authentication?: DetailedAuthentication;
  profilePicture?: BaseProfilePictureInt;
  showNotificationAlert: boolean;

  cuInt: CurrentUserInt;

  constructor(currentUserInt: CurrentUserInt) {
    this.id = currentUserInt.id;
    this.name = currentUserInt.name;
    this.surname = currentUserInt.surname;
    this.role = currentUserInt.role;
    this.allowNotifications = currentUserInt.allowNotifications;
    this.Authentication = currentUserInt.Authentication;
    this.profilePicture = currentUserInt.profilePicture;
    this.cuInt = currentUserInt;

    this.showNotificationAlert = !currentUserInt.allowNotifications;
  }

  get isRoot(): boolean {
    return this.role === 'root';
  }

  get isRootOrAdmin(): boolean {
    return this.isRoot || this.role === 'admin';
  }

  get isUser(): boolean {
    return this.role === 'manager' || this.role === 'leader' || this.role === 'member';
  }

  get fullname(): string {
    return this.name + ' ' + this.surname;
  }

  public setShowNotificationAlert(val: boolean): void {
    this.showNotificationAlert = val;
  }
}
