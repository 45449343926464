import {UserBaseInt} from './user-basic-model';

export interface BaseAppSettingsInt {
  mu_id: number;
}

export interface AppSettingsInt extends BaseAppSettingsInt{
  enableBirthDayCelebrationEmail: boolean;
  enableBirthDayCelebrationSms: boolean;
  editedAt: Date;
  editedBy_User: UserBaseInt;
}

export interface ResourcesLimitsInt {
  documentModule: boolean;
  emailModule: boolean;
  smsModule: boolean;
  smssMax: number;
  emailsMax: number;
  diskSizeMax: number;
}

export interface ResourcesUsageInt {
  emails: number;
  smss: number;
  usersFileSize: number;
  documentsFileSize: number;
  filesThumbSize: number;
  financePaymentsFilesSize: number;
  announcementsFilesSize: number;
  sendMailsTempFileSize: number;
  sendMailsBodyAttachmentSize: number;
}

export interface AppResourcesInt {
  resources: ResourcesLimitsInt;
  usage: ResourcesUsageInt;
}

export class AppResourcesCls {
  resources: ResourcesLimitsInt;
  usage: ResourcesUsageInt;

  constructor(appResources: AppResourcesInt) {
    this.resources = appResources.resources;
    this.usage = appResources.usage;
  }

  public totalDiskUsage(): number {
    if (this.usage) {
      return (this.usage.usersFileSize +
        this.usage.filesThumbSize +
        this.usage.financePaymentsFilesSize +
        this.usage.announcementsFilesSize +
        this.usage.sendMailsTempFileSize +
        this.usage.sendMailsBodyAttachmentSize +
        this.usage.documentsFileSize);
    } else {
      return 0;
    }
  }

  public hasDiskSpace(): boolean {
    return this.resources.diskSizeMax >= this.totalDiskUsage();
  }

  public diskSpaceLeft(): number {
    return this.totalDiskUsage() - this.resources.diskSizeMax;
  }

  public diskSpaceUsage(): number {
    // const _remains: number = this.totalDiskUsage() - this.resources.diskSizeMax;
    return ((this.totalDiskUsage() * 100) / this.resources.diskSizeMax);
  }
}
