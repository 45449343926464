import {Routes} from '@angular/router';
import {AuthGuard} from './core';

export const appRoutes: Routes = [
  {
    path: 'authentication',
    loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    path: 'm',
    canActivateChild: [AuthGuard],
    loadChildren: () => import('./members/members.module').then(m => m.MembersModule)
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/m/dashboard'
  },
  {
    path: '**',
    redirectTo: 'error/404'
  }
];

