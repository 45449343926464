import {environment} from '../../environments/environment';

export class ApiRoutes {

  constructor(
  ) {}

  static get authenticationPath(): string {
    return `${environment.settings.api_url_v1}/authentication`;
  }

  static get usersPath(): string {
    return `${environment.settings.api_url_v1}/users`;
  }

  static get usersDataPath(): string {
    return `${environment.settings.api_url_v1}/users/userdata`;
  }

  static get announcementsPath(): string {
    return `${environment.settings.api_url_v1}/announcements`;
  }

  static get systemPath(): string {
    return `${environment.settings.api_url_v1}/system`;
  }

  static get userFilesPath(): string {
    return `${environment.settings.api_url_v1}/userfiles`;
  }

  static get mePath(): string {
    return `${environment.settings.api_url_v1}/users/me`;
  }

  static get banksPath(): string {
    return `${environment.settings.api_url_v1}/finance/banks`;
  }

  static get clientSpecPath(): string {
    return `${environment.settings.api_url_v1}/clientspec`;
  }

  static get companyPath(): string {
    return `${environment.settings.api_url_v1}/companies`;
  }

  static get companyTypesPath(): string {
    return `${environment.settings.api_url_v1}/companies/types`;
  }

  static get companyTypesListPath(): string {
    return `${environment.settings.api_url_v1}/companies/types/list`;
  }

  static get documentSearchPath(): string {
    return `${environment.settings.api_url_v1}/documents/search`;
  }

  static get documentPath(): string {
    return `${environment.settings.api_url_v1}/documents`;
  }

  static get documentTypePath(): string {
    return `${environment.settings.api_url_v1}/documents/types`;
  }

  static get announcementsNowPath(): string {
    return `${environment.settings.api_url_v1}/announcements/now`;
  }

  static get subscriptionsPath(): string {
    return `${environment.settings.api_url_v1}/subscriptions`;
  }

  static get geodataPath(): string {
    return `${environment.settings.api_url_v1}/geodata/counties/90`;
  }

  static get uploadFilePath(): string {
    return `${environment.settings.api_url_v1}/files/upload`;
  }

  static get filesSearchPath(): string {
    return `${environment.settings.api_url_v1}/documents/files/search`;
  }

  static get fileExtensionsPath(): string {
    return `${environment.settings.api_url_v1}/files/extensions`;
  }

  static get documentTagsPath(): string {
    return `${environment.settings.api_url_v1}/documents/tags`;
  }

  static get personTagsPath(): string {
    return `${environment.settings.api_url_v1}/personTags`;
  }

  static get financePath(): string {
    return `${environment.settings.api_url_v1}/finance`;
  }

  static get paymentsPath(): string {
    return `${environment.settings.api_url_v1}/finance/payments`;
  }

  static get notificationsPath(): string {
    return `${environment.settings.api_url_v1}/notifications`;
  }

  static get userGroupsPath(): string {
    return `${environment.settings.api_url_v1}/usergroups`;
  }

  static get presignedUploadPath(): string {
    return `${environment.settings.api_url_v1}/files/upload/url`;
  }

  static get addressBookPath(): string {
    return `${environment.settings.api_url_v1}/contacts`;
  }

  static get feEmailTemplateStringPath(): string {
    return `${environment.settings.app_fe_url_v1}/emails/templates`;
  }

}


/*
this doesnt work.. :(

export const API_ROUTES_v0 = {
  authenticationPath: `${environment.settings.api_url_v1}/authentication`,
  usersPath:  `${environment.settings.api_url_v1}/users`,
  usersDataPath:  `${environment.settings.api_url_v1}/users/userdata`,
  announcementsPath: `${environment.settings.api_url_v1}/announcements`,
  systemPath: `${environment.settings.api_url_v1}/system`,
  userFilesPath:  `${environment.settings.api_url_v1}/userfiles`,
  mePath: `${environment.settings.api_url_v1}/users/me`,
  banksPath: `${environment.settings.api_url_v1}/finance/banks`,
  clientSpecPath:  `${environment.settings.api_url_v1}/clientspec`,
  companyPath: `${environment.settings.api_url_v1}/companies`,
  companyTypesPath: `${environment.settings.api_url_v1}/companies/types`,
  companyTypesListPath: `${environment.settings.api_url_v1}/companies/types/list`,
  documentSearchPath: `${environment.settings.api_url_v1}/documents/search`,
  documentPath:  `${environment.settings.api_url_v1}/documents`,
  documentTypePath: `${environment.settings.api_url_v1}/documents/types`,
  announcementsNowPath: `${environment.settings.api_url_v1}/announcements/now`,
  subscriptionsPath:  `${environment.settings.api_url_v1}/subscriptions`,
  geodataPath:  `${environment.settings.api_url_v1}/geodata/counties/90`,
  uploadFilePath:  `${environment.settings.api_url_v1}/files/upload`,
  filesSearchPath:  `${environment.settings.api_url_v1}/documents/files/search`,
  fileExtensionsPath:  `${environment.settings.api_url_v1}/files/extensions`,
  documentTagsPath:  `${environment.settings.api_url_v1}/documents/tags`,
  personTagsPath:  `${environment.settings.api_url_v1}/personTags`,
  financePath:  `${environment.settings.api_url_v1}/finance`,
  paymentsPath:  `${environment.settings.api_url_v1}/finance/payments`,
  notificationsPath:  `${environment.settings.api_url_v1}/notifications`,
  userGroupsPath:  `${environment.settings.api_url_v1}/usergroups`,
  presignedUploadPath:  `${environment.settings.api_url_v1}/files/upload/url`,
  addressBookPath:  `${environment.settings.api_url_v1}/contacts`,
  feEmailTemplateStringPath: `${environment.settings.app_fe_url_v1}/emails/templates`
};
*/
