// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  settings: {
    api_url_v1: 'http://127.0.0.1:5000/api/v1',
    app_fe_url_v1: 'http://127.0.0.1:5000/app/frontend/v1',
    storage: 's3',
    appName: 'ÜYE Portal',
    companyName: 'Üye Kontrol ve Doküman Yönetim Uygulaması',
    companyAbridgment: 'uyeport',
    announcementsWebsite: 'uyeport.etutec.com',
    sidebarBrandDocked: 'üye',
    sidebarBrandSmall: 'PORT',
    forgotCompError: 'e-posta adresi bulunamadı. 0312 419 45 71 no\'lu dernek merkezimize ulaşarak bilgilerinizi güncelleyebilirsiniz.',
    brandLogo: 'http://127.0.0.1:5000/public_assets/ui/logos/brand_logo.png',
    brandLogoDark: 'http://127.0.0.1:5000/public_assets/ui/logos/brand_logo_dark.png',
    loginPageBgImage: 'http://127.0.0.1:5000/public_assets/ui/logos/bg_login_page.png'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
