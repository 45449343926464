import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs';
import {distinctUntilChanged, take} from 'rxjs/operators';

import {CurrentUser, CurrentUserInt} from '../models';
import {ApiRoutes} from '../../_definations/app.api.definitions';


@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // @ts-ignore
  private currentUserSubject = new BehaviorSubject<CurrentUser>(null);
  public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());

  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();

  // store the URL so we can redirect after logging in
  redirectUrl: any = null;

  constructor(private _http: HttpClient) {
    console.log('auth service inited.');
    this.redirectUrl = null;
    this.populate();
  }

  setAuth(cuInt: CurrentUserInt): void {
    // Set current user data into observable
    this.currentUserSubject.next(new CurrentUser(cuInt));
    // Set isAuthenticated to true
    this.isAuthenticatedSubject.next(true);
  }

  public get currentUserValue(): CurrentUser {
    // return this.currentUserSubject.value;
    return this.currentUserSubject.getValue();
  }

  populate(): void {
    this._http.get<CurrentUserInt>(`${ApiRoutes.usersPath}/me`)
      .pipe(take(1))
      .subscribe((data: CurrentUserInt) => {
          this.setAuth(data);
        },
        err => {
          console.log('AuthService me err:', err);
          this.purgeAuth();
        }
      );
  }

  purgeAuth(): void {
    // Set current user to an empty object
    this.currentUserSubject.next(null);
    // Set auth status to false
    this.isAuthenticatedSubject.next(false);
  }

  login(_credentials: any): Observable<CurrentUserInt> {
    return this._http.post<CurrentUserInt>(`${ApiRoutes.authenticationPath}/login`, _credentials);
  }

  logout(): Observable<any> {
    return this._http.get(`${ApiRoutes.authenticationPath}/logout`);
  }

  changePassword(body: object = {}): Observable<any> {
    return this._http.post( `${ApiRoutes.authenticationPath}/changePassword`, body);
  }

  changeEmail(userId: number, body: object = {}): Observable<any> {
    return this._http.post(`${ApiRoutes.authenticationPath}/${userId}/changeEmail`, body);
  }

  resetPassword(body: object = {}): Observable<any> {
    return this._http.post(`${ApiRoutes.authenticationPath}/resetPassword`, body);
  }

  verifyPayload(payload: string): Observable<any> {
    return this._http.post(`${ApiRoutes.authenticationPath}/verifyPayload`, {'payload': payload});
  }

  setPasswordWithPayload(body: object = {}): Observable<any> {
    return this._http.post(`${ApiRoutes.authenticationPath}/setPassword`, body);
  }

}
