import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {AuthService} from '../services';

@Injectable()
export class NoAuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const url: string = state.url;
    return this.checkUrl(url);
  }

  checkUrl(url: string): Observable<boolean> {

    return this.authService.isAuthenticated.pipe(take(1),
      map(isAuth => {
        if (isAuth) {
          console.log('Signed in redirecting ->', url);

          // Store the attempted URL for redirecting
          // Bu auth guard da da var ve burada set etmek digerini bozuyor
          // this.authService.redirectUrl = url;

          this.router.navigate(['/m', 'dashboard']);
          // this.router.navigateByUrl(APP_ROUTE_PATHS.members_module.dashboard.base_route);
        }
        // this.authService.redirectUrl = url;
        return !isAuth;
      }));

  }
}
