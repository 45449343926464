import {NgModule, Optional, SkipSelf} from '@angular/core';

// Services
import {AuthService, ErrorReader, TextMaskService} from './services';

// guards
import {AuthGuard, NoAuthGuard, RoleGuard} from './guards';


@NgModule({
  providers: [
    AuthService,
    NoAuthGuard,
    AuthGuard,
    RoleGuard,
    ErrorReader,
    TextMaskService
  ]
})

export class CoreModule {

  constructor(@Optional() @SkipSelf() core: CoreModule) {
    if (core) {
      throw new Error('Core module already inited!');
    }
  }

}
