import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

/*
if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
*/

import { environmentLoader as environmentLoaderPromise } from './environments/environmentLoader';
environmentLoaderPromise.then(env => {
  if (env.production) {
    enableProdMode();
  }
  environment.settings = env.settings;

  platformBrowserDynamic().bootstrapModule(AppModule);
});

import Quill from 'quill';
import ImageResize from 'quill-image-resize-module';
Quill.register('modules/imageResize', ImageResize);

function _window(): any {
  return window;
}


_window().Quill = Quill;
_window().quill = Quill;
