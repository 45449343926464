import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {registerLocaleData} from '@angular/common';
import localeTr from '@angular/common/locales/tr';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {LaddaModule} from 'angular2-ladda';
import {AppComponent} from './app.component';
import {CoreModule} from './core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {appRoutes} from './app-routing';
import {SimpleNotificationsModule} from 'angular2-notifications';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpRequestInterceptor} from './HttpRequestInterceptor';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {QuillModule} from 'ngx-quill';

registerLocaleData(localeTr, 'tr');


// tslint:disable-next-line:typedef
export function provideSwal() {
  return import('sweetalert2').then(({ default: Swal }) => Swal.mixin({
    buttonsStyling: false,
    backdrop: false,
    allowOutsideClick: true,
    confirmButtonText: '<i class="fa fa-exclamation-circle mr-1"></i> Evet',
    cancelButtonText: '<i class="fa fa-check mr-1"></i> Vazgeç',
    customClass: {
      confirmButton: 'btn btn-sm btn-success m-1',
      cancelButton: 'btn btn-sm btn-primary m-1'
    }
  }));
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SweetAlert2Module.forRoot({
      provideSwal
    }),
    SimpleNotificationsModule.forRoot(),
    LaddaModule.forRoot({
      style: 'zoom-in',
      spinnerSize: 22
    }),
    QuillModule.forRoot(),
    CoreModule,
    NgbModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
    {provide: LOCALE_ID, useValue: 'tr'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
