import {HttpErrorResponse} from '@angular/common/http';

export class ErrorReader {

  constructor(
  ) {}

  static getList(error: HttpErrorResponse): string[] {

    console.log(error);
    const retArr = [];
    if (error.error.messages) {

      if (Array.isArray(error.error.messages)) {
        for (const msg of error.error.messages) {
          retArr.push(JSON.stringify(msg));
        }
      } else {
        retArr.push(JSON.stringify(error.error.messages));
      }

    } else {
      // Return error message
      if (error.error.message) {
        console.log(error.error.message);
        return [error.error.message];
      }
      retArr.push(JSON.stringify(error.message));
    }
    return retArr;

  }

}
