import {Injectable} from '@angular/core';

import emailMask from 'text-mask-addons/dist/emailMask';


const alphabetic = /[A-Z]/i;
const digit = /\d/;




@Injectable({
  providedIn: 'root',
})
export class TextMaskService {
  emailMask: Array<string | RegExp>;
  phoneMask: Array<string | RegExp>;
  PHONE_PATTERN = '^\\+(\\d{2})\\s\\((\\d{3})\\)\\s(\\d{3})\\s(\\d{2})\\s(\\d{2})$';

  constructor() {
    this.emailMask = emailMask;
    // this.phoneMask = ['+', '9', '0', ' ', '(', /[1-9]/, digit, digit, ')', ' ', digit, digit, digit, ' ', digit, digit, ' ', digit, digit];
    this.phoneMask = ['+', digit, digit, ' ', '(', /[1-9]/, digit, digit, ')', ' ', digit, digit, digit, ' ', digit, digit, ' ', digit, digit];
  }

}
